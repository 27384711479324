.App {
  font-family: 'Comic Sans MS', sans-serif;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffe18a;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1),
  0px 10px 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.month-title {
  color: #8a4cfc;
  border-bottom: 2px dashed #8a4cfc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.game {
  background-color: #fcf489;
  border: 2px dashed #8a4cfc;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.game-date {
  font-size: 24px;
  color: #8a4cfc;
  font-weight: bold;
}

.game-teams {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  color: #8a4cfc;
  margin-top: 15px;
  font-size: 20px;
}

.current-game {
  background-color: #ffa500;
  border-radius: 15px;
}

.scoreboard {
  position: fixed;
  right: 0;
  top: 0;
  width: 350px;
  height: 100%;
  overflow: auto;
  background-color: #ffe18a;
  border-radius: 15px 0 0 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .scoreboard {
    width: 100%;
  }
}

.score-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.player-name {
  flex-grow: 1;
  color: #8a4cfc;
}

.player-score {
  width: 30px;
  text-align: center;
  color: #8a4cfc;
}

.scoreboard-toggle {
  position: fixed;
  bottom: 15px;
  left: 15px;
  padding: 15px;
  background: #8a4cfc;
  color: #fff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2),
  0px 10px 10px rgba(0, 0, 0, 0.3);
}

.notifications-button {
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 15px;
  background: #8a4cfc;
  color: #fff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2),
  0px 10px 10px rgba(0, 0, 0, 0.3);
}

th {
  text-align: left;
  min-width: 100px;
}

td {
  text-align: left;
}
.game-scores {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-top: 1px solid #ddd;
}
.score-team {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.score-team span:first-child {
  font-weight: bold;
}

.modal {
  position: fixed;
  background: #ffe18a;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  font-family: 'Comic Sans MS', sans-serif;
}

.modal h2 {
  color: #8a4cfc;
  margin-bottom: 10px;
}

.modal .content {
  margin: 10px 0px;
  text-align: center;
}

.modal .content input{
  padding: 10px;
  font-size: 18px;
  border-radius: 10px;
  border: solid 2px #8a4cfc;
}

.modal .actions {
  text-align: center;
}

.modal .actions button {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #8a4cfc;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow:0px 0px 10px rgba(0,0,0,0.2), 0px 10px 10px rgba(0,0,0,0.3);
}